.Data {
  background-color: #f5f5f5;
  text-align: center;
  min-height: 900px;
}

.hidden {
  display: none;
}

.NavBar {
  background-color: #555555;
  color: #ffffff;
  font-size:large;
  text-align: left;
  min-height: 40px;
  border-bottom: 1px solid #d5d5d5;
}

.NavBar-title {
  padding: 20px;
}

.NavBar-item {
  float: right;
  margin: 12px;
  margin-top: 20px;
}

.UploadVideoArea {
  background-color: #656565;
  color: #fff;
  padding: 12px;
  margin: 12px;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
}

.VideoRecord {
  background-color: #ffffff;
  padding: 12px;
  margin: 12px;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  cursor: pointer;
  text-align: left;
}

.VideoLabelingArea {
  background-color: #ffffff;
  padding: 12px;
  margin: 12px;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  text-align: left;
}

.Interval,  .VideoProgressControl {
  background-color: #ffffff;
  margin: 12px 12px 12px 0px;
}

.Interval .Slider {
  margin-top: 12px;
}

.Rating {
  padding-top: 16px;
  text-align: center;
}

.waypoint-cell {
  height: 20px;
  margin: 0px;
  padding: 0;
  display: inline-block;
  cursor: pointer;
}

.waypoint-cell.archived {
  background: #555;
}

.waypoint-card {
  background-color: #ffffff;
  padding: 12px;
  margin-top: 12px;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  text-align: left;
}

.waypoint-card .waypoint-card-title {
  font-size: small;
  font-weight: 100;
  margin-bottom: 0;
}

.waypoint-card p.telemetry span {
  font-size: small;
  border: 1px solid #d5d5d5;
  padding: 4px;
  margin: 4px;
}


.messages-area {
  /*scrollable*/
  overflow-y: scroll;
  flex-grow: 1;
  /*height: 600px; */
  /* set height to 100% of parent container minus the height of the bottom bar */
  background-color: #ffffff;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  text-align: left;
}

.messages-area-container {
  height: calc(100vh - 112px);
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.lower-inner {

}

@media screen and (max-width: 600px) {
  .messages-area {
    height: 400px;
    border: 0px;
    padding: 0px;
    margin: 0px;
  }
}

.properly-formatted-text {
  white-space: pre-line;
}


.message-card-text {
  white-space: pre-line;
}

.faint {
  color: #999;
  font-size: small;
}

.credits-sneak {
  font-size: small;
  float: right;
  padding: 0;
  margin: 0;
  margin-top: -24px;
}

/* the container for a mobile chat text input component that's always visible at the bottom of the screen */
/* the container's height expands to accommodate the div contents */
.new-message-bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border-top: 1px solid #d5d5d5;
  padding: 0 10px;
  align-items: center;
}

.recent-message-details {
  background: #ddd;
  display: inline-block;
  color: #333;
  padding: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 12px;
  margin: 4px;
  font-size: small;
}

.chat-stage-directions-area {
  background: #eee;
  padding: 12px;
  margin-bottom: 12px;
}

.accept-answer-chip {
  font-size: 10;
  padding: 4px;
  cursor: pointer;
  border-radius: 12px;
  float: right;
}

.accept-answer-chip:hover {
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.edit-chip {
  background-color: #ffffff;
  font-size: small;
  padding: 4px;
  margin-top: -32px;
  z-index: 100;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #d5d5d5;
  text-align: left;
  float: right;
  opacity: 0.1;
}

.meta-message-card {
  margin: 2px;
  border: 1px solid #d5d5d5;
  text-align: right;
  cursor: pointer;
  /* make it more card like, add a shadow */
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(123, 123, 123, 0.2);
}

.meta-message-card-title {
  cursor: pointer;
  background-color: #777;
  color: #fff;
  padding: 4px;
  padding-right: 12px;
  padding-left: 32px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(123, 123, 123, 0.2);
}

/* make the edit chip fade into view when the mouse is over the .message-card-chips parent div */
.message-card-chips:hover .edit-chip {
  opacity: 1;
  transition: opacity 0.3s ease-out;
}


.clear-floats {
  clear: both;
}



.jumping-dots span {
  position: relative;
  bottom: 0px;
  animation: jump 2s infinite;
}
.jumping-dots .dot-1{
  animation-delay: 200ms;
}
.jumping-dots .dot-2{
  animation-delay: 400ms;
}
.jumping-dots .dot-3{
  animation-delay: 600ms;
}

@keyframes jump {
  0%   {bottom: 0px;}
  20%  {bottom: 5px;}
  40%  {bottom: 0px;}
}

/* make a pseudo-message-card that starts at 0% opacity and fades in to 100%*/
.pseudo-message-card {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  90% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

.character-chat-icon {
  display: inline-block;
  margin-right: 8px;
  margin-top: -12px;
  margin-left: -8px;
}

pre {
  overflow-x: auto;
}

/*start of code highlighting*/
/**
 * Inspired by gatsby remark prism - https://www.gatsbyjs.com/plugins/gatsby-remark-prismjs/
 * 1. Make the element just wide enough to fit its content.
 * 2. Always fill the visible space in .code-highlight.
 */
.code-highlight {
  float: left; /* 1 */
  min-width: 100%; /* 2 */
}

.code-line {
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: -16px;
  margin-right: -16px;
  border-left: 4px solid rgba(0, 0, 0, 0); /* Set placeholder for highlight accent border color to transparent */
}

.code-line.inserted {
  background-color: rgba(16, 185, 129, 0.2); /* Set inserted line (+) color */
}

.code-line.deleted {
  background-color: rgba(239, 68, 68, 0.2); /* Set deleted line (-) color */
}

.highlight-line {
  margin-left: -16px;
  margin-right: -16px;
  background-color: rgba(55, 65, 81, 0.5); /* Set highlight bg color */
  border-left: 4px solid rgb(59, 130, 246); /* Set highlight accent border color */
}

.line-number::before {
  display: inline-block;
  width: 1rem;
  text-align: right;
  margin-right: 16px;
  margin-left: -8px;
  color: rgb(156, 163, 175); /* Line number color */
  content: attr(line);
}
/* end the markdown stuff */

.objective-score-chip {
  display: inline-block;
 background-color: #ffffff;
  font-size: small;
  float: right;
  margin-right: 4px;
  margin-top: -8px;
  padding: 4px;
}

.credit-cost-chip {
  display: inline-block;
  background-color: #999;
  color: #fff;
  font-size: small;
  float: right;
  margin-right: -12px;
  margin-top: -8px;
  padding: 4px;
  padding-right: 8px;
}

.chat-options-area {
  height: calc(100vh - 234px);
  overflow-y: scroll;
  padding-right: 12px;
}

.mmcard {
  display: flex;
  font-size: 1.2em;
  margin: 24px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(123, 123, 123, 0.2);
  padding-bottom: 0;
  background-color: #fff;
}

.mmcard-image-container {
  flex: 0 0 auto;
  align-self: flex-start;
}

.mmcard-right {
  flex: 1 1 auto;
  align-self: flex-start;
  padding: 12px;
  padding-left: 20px;
  padding-right: 0px;
}

.mmcard-header {
  color: #888;
}

.mmcard-text {
}